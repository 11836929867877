import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import map from "../../assets/map.png";
import wpp from "../../assets/wpp.png";
import email from "../../assets/email.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="logo">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="item">
            <p className="item_title">Endereço:</p>

            <div className="plural">
              <a
                href="https://maps.app.goo.gl/NDvJY13CMqaY3FYj6"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bottom">
                  <figure>
                    <img
                      src={map}
                      alt="Avenida Paulo Ayres, nº 75, Bloco 34, Conjunto 21, CEP: 06767-220, Taboão da Serra SP."
                    />
                  </figure>

                  <p className="item_text">
                    Avenida Paulo Ayres, nº 75, Bloco 34, Conjunto 21, CEP:
                    06767-220, Taboão da Serra SP.
                  </p>
                </div>
              </a>
            </div>
          </div>

          <div className="item">
            <p className="item_title">Contato:</p>

            <div className="plural">
              <a
                href="http://wa.me/+5511984993680?text=Olá,%20Gostaria%20de%20falar%20com%20um%20representante"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bottom">
                  <figure>
                    <img src={wpp} alt="(11) 98499-3680" />
                  </figure>

                  <p className="item_text">(11) 98499-3680</p>
                </div>
              </a>

              <a
                href="http://wa.me/+5511994410596?text=Olá,%20Gostaria%20de%20falar%20com%20um%20representante"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bottom">
                  <figure>
                    <img src={wpp} alt="(11) 99441-0596" />
                  </figure>

                  <p className="item_text">(11) 99441-0596</p>
                </div>
              </a>
            </div>
          </div>

          <div className="item">
            <p className="item_title">E-mail:</p>

            <div className="plural">
              <a
                href="mailto:calculos.edilson@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bottom">
                  <figure>
                    <img src={email} alt="calculos.edilson@gmail.com" />
                  </figure>

                  <p className="item_text">calculos.edilson@gmail.com</p>
                </div>
              </a>

              <a
                href="mailto:rafael@hgm-acontabil.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bottom">
                  <figure>
                    <img src={email} alt="rafael@hgm-acontabil.com.br" />
                  </figure>

                  <p className="item_text">rafael@hgm-acontabil.com.br</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Uni Cálculos Perícia Contábil - 2025 - Todos os direitos reservados
          </p>

          <p className="text">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
